.btn-menu{
    position: relative;
    width:100% !important;
    padding:14px 16px;
    label {
        position: absolute;
        left: 0;
        top: 0;
        -webkit-transform: translate(-3px, -11px) scale(0.75);
        -moz-transform: translate(-3px, -11px) scale(0.75);
        -ms-transform: translate(-3px, -11px) scale(0.75);
        transform: translate(-3px, -11px) scale(0.75);
        -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        z-index: 1;
        pointer-events: auto;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: #FFF;
        padding: 0 3px;
        white-space:nowrap;
    }
}
