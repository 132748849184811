@import "./tailwind.scss";

@import "./vars.scss";

@import "./main.scss";

@import "./footer.scss";

@import "./homeFilterBox.scss";

@import "./navbar.scss";

@import "./button.scss";

.MuiChip-filled.MuiChip-colorPrimary {
  background-color: $color-primary;
}
