@tailwind base;

@tailwind components;

@tailwind utilities;

@layer components {
  .btn {
    text-transform: none;
  }
}
