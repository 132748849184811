footer {
    .logo{
        img{
            height:2rem;
            width: auto;
        }
    }
    .footer-col-title{
        &:after{
            content:"";
            width:2.5rem;
            height: 1px;
            background-color: $color-primary;
        }
    }
    a{
        color: #fff;
    }
}