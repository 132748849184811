section {
    padding: calc(1rem + 4vw) 0;
    position: relative;
    .container {
        gap: calc(1rem + 1.5vw);
    }
    &.first{
        padding-top: calc(0.5rem + 2vw); 
    }
}

.container {
    max-width: 1400px !important;
    // max-width: 100% !important;
    padding-left: calc(1rem + 0.5vw);
    padding-right: calc(1rem + 0.5vw);
    margin: 0 auto;
    width: 100% !important;
    overflow: hidden !important;
}
