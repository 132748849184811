@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?7wwkf6');
  src:  url('fonts/icomoon.eot?7wwkf6#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?7wwkf6') format('truetype'),
    url('fonts/icomoon.woff?7wwkf6') format('woff'),
    url('fonts/icomoon.svg?7wwkf6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu:before {
  content: "\e914";
}
.icon-clear:before {
  content: "\e915";
}
.icon-close:before {
  content: "\e915";
}
.icon-line-chart:before {
  content: "\e913";
}
.icon-shield1:before {
  content: "\e912";
}
.icon-diamond1:before {
  content: "\e911";
}
.icon-arrow-right:before {
  content: "\e916";
}
.icon-menu1:before {
  content: "\e903";
}
.icon-pin:before {
  content: "\e900";
}
.icon-message:before {
  content: "\e901";
}
.icon-user:before {
  content: "\e902";
}
.icon-mail:before {
  content: "\e904";
}
.icon-phone:before {
  content: "\e905";
}
.icon-jobs-search:before {
  content: "\e906";
}
.icon-realestate-management:before {
  content: "\e907";
}
.icon-realestate-deal:before {
  content: "\e908";
}
.icon-headphone:before {
  content: "\e909";
}
.icon-linkedin:before {
  content: "\e90a";
}
.icon-x:before {
  content: "\e90b";
}
.icon-instagram:before {
  content: "\e90c";
}
.icon-facebook:before {
  content: "\e90d";
}
.icon-twitter:before {
  content: "\e90e";
}
.icon-envelop:before {
  content: "\e90f";
}
.icon-logo:before {
  content: "\e910";
}
.icon-owl:before {
  content: "\e920";
}
.icon-double-arrows:before {
  content: "\e921";
}
.icon-linkedin-logo:before {
  content: "\e922";
}
.icon-target:before {
  content: "\e925";
}
.icon-shield:before {
  content: "\e926";
}
.icon-chart-arrow-up:before {
  content: "\e927";
}
.icon-diamond:before {
  content: "\e928";
}
.icon-arrrow-circle:before {
  content: "\e929";
}
.icon-arrow:before {
  content: "\e92a";
}
