.btn {
    padding: 0.8rem 1.2rem;
    background-color: $color-secondary;
    color: #fff;
    border-radius: $border-radius;
    &.btn-small {
        padding: 0.4rem 0.6rem;
        font-size: 0.9rem;
    } 
    &.bg-white {
        background-color: #fff;
        color: $color-secondary;
    }
    &.bg-primary {
        color: #fff;
        background-color: $color-primary;
    }
    &.btn-blue {
        color: #fff;
        background-color: #80A1CB;
    }
    &.outlined {
        color: #fff;
        background-color: $color-primary;
    }   
    &.white,  &.btn-white  {
        background-color: #fff;
        color: $color-text;
    }
    &.btn-whatsapp  {
        background-color: #075E54;
        color: #fff;
    }
    &.btn-menu {
        color: #666666;
        background-color: #fff;
        border: 1px solid #C4C4C4;
        padding: 14px 16px;
        text-align: left;
    }
    &.rounded-none{
        border-radius: 0;
    }


}
.btn-box{
    padding: 0.8rem 1.2rem;
}

.btn-box-x{
    padding-left: 1.2rem;
    padding-right: 1.2rem;
}




