nav {
  position: absolute;
  left: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
  z-index: 20;

  .nav-item {
    font-weight: 400;
    font-size: 0.9rem !important;
  }

  .logo {
    width: calc(10rem + 15vmin);
    background-position: center left;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.open, &.inner {
    .logo {
      fill: white;
    }

    .logo .dark {
      fill: black;
    }
  }

  &.inner {
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity, 1)) !important;
    color: $color-text;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    position: relative;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;

    a {
      color: $color-text;
    }

    a.btn {
      color: #FFF;
    }
  }

  a {
    color: #FFF;
  }

}

.breadcrumbs {
  a {
    color: $color-muted;
  }
}
