.home-filters {
    .tab.active{
        background: $color-primary;
        color: white;
        &:hover {
            background: $color-primary;
        }
        position: relative;
        &:before{
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid $color-primary;
            bottom: -10px;
            left: 50%;
            margin-left: -9px;
        }
    }
}
