.spotlight {
    position: relative;
    &:before {
        content: "";
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 73.51%, rgba(0, 0, 0, 0.2) 100%), rgba(0, 0, 0, 0.3);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 5;
        position: absolute;
    }
}
