html {
  scroll-behavior: smooth;
}

body,
html {
  margin: 0;
  padding: 0;
  min-height: 100%;
  font-size: 16px;
  font-family: $font;
  line-height: 1.5;
  background-color: #f7f7f7;
  color: $color-text;
}

* {
  box-sizing: border-box;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

b {
  font-weight: bold;
}

.box {
  border-radius: $border-radius;
  overflow: hidden;
}

h1,
h2 {
  font-family: $font-headers;
  font-weight: bold;
  color: $color-primary;
  line-height: 1.2;

  .subtitle {
    font-weight: 300;
  }
}

h1 {
  font-size: calc(1.4rem + 1.4vw);
}

h1 + h3 {
  margin-top: calc(0.5rem);
  // line-height: 1;
}

h2 {
  font-size: calc(1.2rem + 1.3vw);
}

h3 {
  font-size: calc(0.9rem + 0.3vw);
  font-weight: 100;
  line-height: 1.2;
}

h2 + h3 {
  margin-top: calc(0.5rem);
  // line-height: 1;
}

p {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.bg-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #fff;
}

a {
  color: $color-primary;
}

.home-caption {
  position: relative;
  height: 60vh;
  width: 60vh;

  &:before {
    content: "";
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    background: rgba($color-secondary, 0.8);
    mix-blend-mode: hard-light;
    z-index: 0;
    border-radius: 100%;
  }

  h1 {
    font-size: calc(1rem + 10vw);
    line-height: 0.8;

    .subtitle {
      margin-top: calc(1rem + 1vw);
      font-size: 50%;
    }
  }

  .btn {
    margin: calc(1rem + 2vw);
    margin-bottom: 2vw;
  }
}

.rounded {
  border-radius: $border-radius;
}

.btn.linkedin .icon {
  margin-bottom: 1px;
}


/********************************************/

#home-about .container {
  &:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #ccc;
    top: 0;
  }
}

.section-img {
  min-height: 30vw;
}

/********************************************/

.card {
  background: #fff;
  position: relative;
  display: flex !important;
  flex-direction: column;
  height: 100%;
  gap: 0.75rem;

  .card-img {
    height: 0;
    padding-top: 60%;
  }

  .card-logo {
    height: 0;
    width: 40%;
    padding-top: calc(40% - 8px);
    border-radius: 100%;
    border: solid 4px $color-secondary;
    // background-size: 80%;
    box-sizing: border-box;
    margin: -30% auto 0;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0 calc(1rem + 3%) calc(1rem + 3%);
  }
}

@media (orientation: portrait) {
  .home-caption {
    height: 60vw;
    width: 60vw;
  }
}

.card:hover,
.card-company:hover {
  .mix-blend {
    &:before {
      opacity: 0.8;
    }
  }
}

ul.disc, article ul {
  li {
    display: flex;
    align-items: center;
  }

  li:before {
    content: "";
    margin-right: 0.5rem;
    height: 0.5rem;
    width: 0.5rem;
    background: $color-primary;
    border-radius: 1rem;

  }
}

[data-fancybox] {
  @apply pointer-events-none select-none;
}

html.fancybox-initiated {
  [data-fancybox] {
    @apply pointer-events-auto select-none;
  }
}
